import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import ImgBox from '../ImgBox'
import missingImage from './missing_image.jpg'


const GrowImg = styled.div`
    overflow: hidden;
    display: inline-block;
    margin: 5px;
    height: 110px;
    width: 110px;
    border-radius: 5px;
    &:hover > #grower {
        transition: .2s;
        transform: scale(1.05);
    }
`


const DomainReveal = ({ domain: { cover_image, domain_name, custom_link } }) => {
    if (!cover_image.localFile) return null;
    const href = custom_link ? custom_link : `/details/${domain_name.toLowerCase()}`;

    return (<Link to={href}>
        <GrowImg>
            <div id="grower">
                {cover_image && cover_image.localFile ?
                    <Img
                        fixed={cover_image.localFile.childImageSharp.fixed}
                        alt={domain_name}
                    /> :
                    <ImgBox
                        src={missingImage}
                        width={200}
                        height={200}
                        style={{
                            border: '1px solid grey',
                            display: 'inline-block',
                            boxSizing: 'border-box'
                        }}
                    />
                }
            </div>
        </GrowImg>
    </Link>)
};

DomainReveal.propTypes = {};

export default DomainReveal;