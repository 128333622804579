import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled(props => <Link {...props} />)`
    color: grey;
    text-transform: uppercase;
    border: 1px solid lightgrey;
    border-radius: 16px;
    padding: 5px 20px;
    text-decoration: none;
    margin: 5px auto;
    display: inline-block;
    transition: .25s;
    &:hover {
        background-color: green;
        color: white;
    }
`

const SubtleCta = props => {
    return (<StyledLink to={props.to}>
        {props.label}
    </StyledLink>)
};

SubtleCta.propTypes = {
    label: PropTypes.string
};

export default SubtleCta;