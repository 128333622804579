import React, { Fragment } from 'react';
import styled from 'styled-components';

const Titlebar = styled.div`
    padding: 10px;
    color: grey;
    background-color: powderblue;
    text-transform: uppercase;
`



const SideBox = props => {
    // console.log(domains)
    return (<Fragment>
        <Titlebar>{props.title}</Titlebar>
        <div style={{
            padding: '15px',
            backgroundColor: 'white',
            textAlign: 'center',
            ...props.style
        }}>
            {props.children}
        </div>
    </Fragment>)
};

SideBox.propTypes = {};

export default SideBox;